import { graphql, useStaticQuery } from "gatsby";
import { Observer } from 'mobx-react-lite';
import React from "react"; // this is used by Observer (when observer is present)
import betterProductivityVideo from '../../../features/better-productivity/better-productivity.mp4';
// import timeTrackingImage from '../../../features/time-tracking/time-tracking.jpg';
import timeTrackingVideo from '../../../features/time-tracking/time-tracking.mp4';
// import visualisePerformanceVideo from '../../../features/visualise-performance/visualise-performance.mp4';
import visualisePerformanceVideo from '../../../features/visualise-performance/visualise-performance.mp4';
import CTASectionTrial from "../../components/CTASectionTrial/CTASectionTrial";
import DefaultPageHeader from '../../components/DefaultPageHeader/DefaultPageHeader';
import FeatureBlock from "../../components/FeatureBlock/FeatureBlock";
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import { PageComponent } from '../../types/gatsby.types';
import { TracksterAllFeaturesQuery } from '../../types/generated';
import { ColorPalette } from '../../utils/colors.utils';
import '../features.scss';

const PageFeatures: PageComponent = props => {

  const AllFeatures: TracksterAllFeaturesQuery = useStaticQuery(graphql`
    query TracksterAllFeatures {
      allPosts: allMdx(
        filter: {fileAbsolutePath: {regex: "/features/"}}
        ) {
        nodes {
          frontmatter {
            title
            description
            overline
            featured_video {
              publicURL
            }
            featured_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            color
          }
          id
          slug
        }
      }
    }`
  )

  return <Observer children={() => (
    <PageTemplate 
      {...props} // always include
      className="PageFeatures"
      title="Features"
      description="Trackster Features"
      backgroundColor={ColorPalette.white}
      foregroundColor={ColorPalette.dark}
    >
      <DefaultPageHeader
        id="FeaturesPageHeader"
        title="Affordable and Effortless Project Management"
        overline="Main features"
        // subtitle="Check why trackster is the best solution for your business"
        children={
          <div>
            <p>Trackster is a simple to use, low cost all-in-one time tracking and project management tool for high performing projects.</p>
          </div>
        }
      ></DefaultPageHeader>

      {/* <FeatureBlockList
      id=""
      allMdx={AllFeatures.allPosts}
      ></FeatureBlockList> */}

      {/* {console.log(timeTrackingImage)} */}

        <FeatureBlock 
        id="TimeTrackingFeatureBlock"
        title="Acurate, Transparent Time-Tracking"
        overline="Track everything"
        // image={timeTrackingImage}
        videoFilePath={timeTrackingVideo}
        color={'blue'}
        link="/features/time-tracking"
        children={
          <div>
            <p>Increase productivity through real-time and manual time tracking. Our automatic timesheet software allows your company to stay on track of projects. That way you can bill your team accurately and keep team members accountable.</p>
          </div>
        }
        ></FeatureBlock>

        <FeatureBlock 
        id="BenchmarkFeatureBlock"
        title={
          <span>The Power of <em>Five Softwares in One</em></span>
        }
        color={'magenta'}
        children={
          <div>
            <p>Trackster.io combines the capabilities of multiple management systems into one streamlined solution. Say goodbye to Jira, Todoist, Trello and Toggl (Slack soon), trackster.io is the only project management software that you need.</p>
          </div>
        }
        ></FeatureBlock>

        <FeatureBlock 
        id="VisualisePerformanceFeatureBlock"
        title="Easily Visualise Performance"
        overline="See the big picture"
        color={'green'}
        // image={visualisePerformanceImage}
        videoFilePath={visualisePerformanceVideo}
        link="/features/insights"
        children={
          <div>
            <p>We want you to see exactly how trackster.io is improving your company’s performance. Gantt Charts, Kanban Boards and Calendars visually display your work. Our automatic reporting functionality can clearly display your productivity in just a few clicks.</p>
          </div>
        }
        ></FeatureBlock>

        <FeatureBlock 
        id="BetterProductivityFeatureBlock"
        title="Better Productivity from Day One"
        overline="Enhanced Kanbans"
        // image={betterProductivityImage}
        videoFilePath={betterProductivityVideo}
        color={'yellow'}
        link="/features/projects"
        children={
          <div>
            <p>
              Our intuitive project management system is so simple that you can learn how to use it in just 3 minutes. Our team has pinpointed the features that your business actually needs, so you aren’t overloaded. This means there is no learning curve to overcome and you can reap the benefits of trackster.io from the get go.
            </p>
          </div>
        }
        ></FeatureBlock>

        <CTASectionTrial
        title="See all features in action"
        />

    </PageTemplate>
  )} />
}

export default PageFeatures;